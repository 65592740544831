import { defineStore } from "pinia";

export const useDefaultStore = defineStore("default", {
  state: () => {
    return {
      navElements: [],

      cartComparison: null,
      campaigns: [],
      brands: [],
      currentListing: {},
      restoreListing: null,
      currentCategory: {},
      rootCategory: {},
      flatCategories: {},
      animalSpecies: {},
      activeAnimalSpecies: {},
      page: {},
      currentPath: null,
      currentFullPath: null,
      currentProduct: {},
      strapiContent: {},
      strapiSeo: {},
      pageType: "default",
      globalPromotion: null,
      strapiGlobalOptions: null,
      prefetchedRoutes: [],
      cookieConsent: false,
      allowAddToCart: true,
      dtcParameter: false,
      viewComponent: "fallbackPage",
    };
  },

  // contains your mutations
  actions: {
    setNavElements(value) {
      this.navElements = value;
    },

    setCartComparison(value) {
      this.cartComparison = value;
    },
    setCampaigns(value) {
      this.campaigns = value;
    },
    setBrands(value) {
      this.brands = value;
    },
    setCurrentListing(value) {
      this.currentListing = value;
    },
    setRestoreListing(value) {
      this.restoreListing = value;
    },
    setCurrentCategory(value) {
      this.currentCategory = value;
    },
    setRootCategory(value) {
      this.rootCategory = value;
    },
    setFlatCategories(value) {
      this.flatCategories = value;
    },
    setAnimalSpecies(value) {
      this.animalSpecies = value;
    },
    setActiveAnimalSpecies(value) {
      this.activeAnimalSpecies = value;
    },
    setCms(value) {
      this.cms = value;
    },
    setPage(value) {
      this.page = value;
    },
    setCurrentPath(value) {
      this.currentPath = value;
    },
    setCurrentFullPath(value) {
      this.currentFullPath = value;
    },
    setCurrentProduct(value) {
      this.currentProduct = value;
    },
    setStrapiContent(value) {
      this.strapiContent = value;
    },
    setStrapiGlobalOptions(value) {
      this.strapiGlobalOptions = value;
    },
    setStrapiSeo(value) {
      this.strapiSeo = value;
    },
    setPageType(value) {
      this.pageType = value;
    },
    setGlobalPromotion(value) {
      this.globalPromotion = value;
    },

    setPrefetchedRoutes(value) {
      this.prefetchedRoutes = value;
    },
    addPrefetchedRoutes(value) {
      this.prefetchedRoutes.unshift(value);
    },
    setCookieConsent(value) {
      this.cookieConsent = value;
    },
    setAllowAddToCart(value) {
      this.allowAddToCart = value;
    },
    setDtcParameter(value) {
      this.dtcParameter = value;
    },
    setViewComponent(value) {
      this.viewComponent = value;
    },
  },
  // your root getters
  getters: {
    getNavElements() {
      return this.navElements;
    },

    getCartComparison() {
      return this.cartComparison;
    },
    getCampaigns() {
      return this.campaigns;
    },
    getBrands() {
      return this.brands;
    },
    getCurrentListing() {
      return this.currentListing;
    },
    getRestoreListing() {
      return this.restoreListing;
    },
    getCurrentCategory() {
      return this.currentCategory;
    },
    getFlatCategories() {
      return this.flatCategories;
    },
    getAnimalSpecies() {
      return this.animalSpecies;
    },
    getActiveAnimalSpecies() {
      return this.activeAnimalSpecies;
    },
    getCms() {
      return this.cms;
    },
    getPage() {
      return this.page;
    },
    getCurrentPath() {
      return this.currentPath;
    },
    getCurrentFullPath() {
      return this.currentFullPath;
    },
    getCurrentProduct() {
      return this.currentProduct;
    },
    getStrapiContent() {
      return this.strapiContent;
    },
    getStrapiGlobalOptions() {
      return this.strapiGlobalOptions;
    },
    getStrapiSeo() {
      return this.strapiSeo;
    },
    getPageType() {
      return this.pageType;
    },
    getGlobalPromotion() {
      return this.globalPromotion;
    },
    getPrefetchedRoutes() {
      return this.prefetchedRoutes;
    },
    getCookieConsent() {
      return this.cookieConsent;
    },
    getAllowAddToCart() {
      return this.allowAddToCart;
    },
    getDtcParameter() {
      return this.dtcParameter;
    },
    getViewComponent() {
      return this.viewComponent;
    },
  },
});
