// import defaults from "~/public/pwa-defaults/api-defaults.json";
// import { useListing } from "~/composables/useListing";
// import { useStrapi } from "~/composables/useStrapi";
// import { useCategory } from "~/composables/useCategory";

// Definieren Sie die Typen für die Routenobjekte
interface Route {
  path: string;
  query: any;
  fullPath: string;
  // Fügen Sie hier weitere Eigenschaften hinzu, die Sie benötigen
}

// Definieren Sie den Typ für das Store-Objekt
interface Store {
  currentPath: string;
  getCurrentListing: any; // Ersetzen Sie 'any' durch den tatsächlichen Typ
  getCurrentCategory: any; // Ersetzen Sie 'any' durch den tatsächlichen Typ
  getCurrentProduct: any; // Ersetzen Sie 'any' durch den tatsächlichen Typ
  currentFullPath: string;
  setRestoreListing: (data: any) => void; // Ersetzen Sie 'any' durch den tatsächlichen Typ
  setCurrentListing: (listing: any) => void; // Ersetzen Sie 'any' durch den tatsächlichen Typ
  setStrapiSeo: (seo: any) => void; // Ersetzen Sie 'any' durch den tatsächlichen Typ
  setStrapiContent: (content: any) => void; // Ersetzen Sie 'any' durch den tatsächlichen Typ
  setActiveAnimalSpecies: (species: any) => void; // Ersetzen Sie 'any' durch den tatsächlichen Typ
  setPageType: (type: string) => void;
  setCurrentPath: (path: string) => void;
  setCurrentFullPath: (path: string) => void;
  setViewComponent: (path: string) => void;
  flatCategories: any[];
  setCurrentCategory: any;
  setPage: any;
  setCurrentProduct: any;
  pageType: string;
  // Fügen Sie hier weitere Methoden und Eigenschaften hinzu, die Sie benötigen
}

export default defineNuxtRouteMiddleware(async (to: Route, from: Route) => {
  /**
   * A place to plug in some actions during changing the pages/routes.
   * For instance: broadcast events for specific page
   */
  const app: any = useNuxtApp();
  const store: Store = useDefaultStore();
  const states = useStates();
  const { fetchProduct } = getProduct({ path: to });

  if (store.currentPath !== to.path && !to.path.startsWith("/static/")) {
    states.setIsSwitchingPages(true);
    const { getActiveAnimalSpecies } = useCategory();

    let currentCategory = null;

    if (
      !process.server &&
      store.getCurrentListing &&
      store.getCurrentCategory &&
      store.currentFullPath
    ) {
      store.setRestoreListing({
        listing: store.getCurrentListing,
        scrollPos: window.scrollY,
        category: store.getCurrentCategory.id,
        path: store.currentFullPath,
      });
    }

    store.setCurrentListing(null);
    store.setStrapiSeo(null);
    store.setStrapiContent(null);
    store.setActiveAnimalSpecies(null);

    //----------------------------------------------------------------------------------------------------------------------//
    //Set current path
    store.setCurrentPath(to.path);
    store.setCurrentFullPath(to.fullPath);

    //----------------------------------------------------------------------------------------------------------------------//
    // Set currentCategory
    if (store.flatCategories.length) {
      const categories = JSON.parse(JSON.stringify(store.flatCategories));
      let findCategory = categories.find((item: any) => {
        if (item && item.seoUrls && item.seoUrls.length) {
          return item.seoUrls?.[0]?.seoPathInfo === to.path.substring(1);
        } else {
          return null;
        }
      });

      if (findCategory) {
        store.setCurrentCategory(findCategory);
        currentCategory = findCategory;

        // Check if you can set an active animal species
        getActiveAnimalSpecies(currentCategory);
      } else {
        store.setCurrentCategory(null);
      }
    }

    //----------------------------------------------------------------------------------------------------------------------//
    //get Strapi Pages

    const { getStrapiPage } = useStrapi({
      route: to,
    });
    await getStrapiPage().then(() => {});

    //----------------------------------------------------------------------------------------------------------------------//
    //Get Listing or Page
    // is Product?
    //Fetch Product

    if (to.path.startsWith("/p/") || to.path.startsWith("/detail/")) {
      await fetchProduct().then(() => {
        // store.setCurrentListing(null);
      });
    } else if (to.path !== "/" && !to.path.startsWith("/marken/")) {
      // is category
      // fetch category
      if (currentCategory) {
        const { getListing } = useListing({
          listingId: currentCategory.id,
          debug: "View",
          listingRoute: to,
        });

        await getListing({
          prop: to.query,
          page: to.query?.p || 1,
          debug: "view",
          routeChange: true,
        }).then(() => {});
      } else {
        store.setCurrentListing({});
      }
      // return redirect(app.$routing.getUrl(route.path))
    } else {
      // return;
    }

    //----------------------------------------------------------------------------------------------------------------------//
    //define page type

    store.setPageType(getPageType(to));

    states.setIsSwitchingPages(false);
  }

  return;
});
