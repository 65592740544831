export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"hid":"og:site_name","name":"og:site_name","content":"inpetto"},{"hid":"apple-mobile-web-app-title","name":"apple-mobile-web-app-title","content":"inpetto"},{"hid":"project-type","name":"project-type","content":"inpetto webstore"}],"link":[],"style":[],"script":[{"hid":"gtm","innerHTML":"window.dataLayer = window.dataLayer || [];","type":"text/javascript"},{"hid":"cookiemanager","src":"/js/cookiemanager.js","type":"text/javascript"},{"children":"if(window.location.ancestorOrigins.contains(\"https://pagesense.zoho.eu\")){var e=document.createElement(\"script\");e.setAttribute(\"src\",\"https://cdn-eu.pagesense.io/js/petcloud/f4f71201449b42d7b3975f95317aa3f8.js\"),document.head.appendChild(e)}"},{"type":"text/javascript","class":"cmplazyload","data-cmp-vendor":"s2536","children":"!function(e,t,n){e.TiktokAnalyticsObject=n;var o=e[n]=e[n]||[];o.methods=[\"page\",\"track\",\"identify\",\"instances\",\"debug\",\"on\",\"off\",\"once\",\"ready\",\"alias\",\"group\",\"enableCookie\",\"disableCookie\"],o.setAndDefer=function(e,t){e[t]=function(){e.push([t].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<o.methods.length;i++)o.setAndDefer(o,o.methods[i]);o.instance=function(e){for(var t=o._i[e]||[],n=0;n<o.methods.length;n++)o.setAndDefer(t,o.methods[n]);return t},o.load=function(e,t){var i=\"https://analytics.tiktok.com/i18n/pixel/events.js\";o._i=o._i||{},o._i[e]=[],o._i[e]._u=i,o._t=o._t||{},o._t[e]=+new Date,o._o=o._o||{},o._o[e]=t||{};var a=document.createElement(\"script\");a.type=\"text/javascript\",a.async=!0,a.src=i+\"?sdkid=\"+e+\"&lib=\"+n;var s=document.getElementsByTagName(\"script\")[0];s.parentNode.insertBefore(a,s)},o.load(\"CGQI5J3C77U0SLUG12CG\"),o.page()}(window,document,\"ttq\");"}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1","title":"inpetto"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"